<template>
  <AppLogin origin="event-login">
    <template #before-form>
      <div class="d-flex justify-center mb-4">
        <v-img
          max-height="100"
          src="@/assets/icons/boston-lights.png"
          contain />
      </div>
    </template>

    <template #after-form>
      <div class="d-flex justify-center mt-6">
        <v-img
          max-height="50"
          src="@/assets/icons/SOURCERY_poweredby_web.png"
          contain />
      </div>
    </template>
  </AppLogin>
</template>

<script>
export default {
  name: 'EventLogin',
  components: {
    AppLogin: () => import('@/views/Login'),
  },
};
</script>